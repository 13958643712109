// Philip was here
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import VueLazyload from "vue-lazyload";
import VueMeta from "vue-meta";
import VueSweetalert2 from "vue-sweetalert2";
import vClickOutside from "v-click-outside";
import ServiceWorker from "@/lib/sw";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Import global styles
import "@/styles/index.scss";

// Disabled since sweetalert2 is part of vue-sweetalert2
// eslint-disable-next-line import/no-extraneous-dependencies
import "sweetalert2/dist/sweetalert2.min.css";

ServiceWorker();

// Libraries
Vue.use(require("underscore"));
Vue.use(require("v-calendar"));
Vue.use(require("vue-closable"));
Vue.use(require("vue-moment"));

Vue.use(vClickOutside);
Vue.use(VueApexCharts);
Vue.use(VueLazyload);
Vue.use(VueMeta);
Vue.use(VueSweetalert2);

Vue.component("apexchart", VueApexCharts);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
