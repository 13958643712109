import store from "@/store/index";
import axios from "axios";

export default function execute() {
  axios.interceptors.request.use((config) => {
    if (config.url.includes("api.netwrk.gg")) {
      const userId = store?.state?.userInfo?._id;
      const userType = store?.state?.userInfo?.type;

      if (userId) {
        config.headers.user_id = userId;
      }

      if (userType) {
        config.headers.user_type = userType;
      }
    }

    return config;
  });
}
