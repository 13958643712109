<template>
  <div id="app" class="w-full h-full min-h-screen">
    <Header v-if="getUserInfo && desktop" class="container px-8 md:px-0 mx-auto" />

    <BottomNav v-if="getUserInfo && !desktop" />

    <div class="container px-8 md:px-0 pt-4 pb-24 md:pb-8 mx-auto h-full">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "App",

  metaInfo: {
    title: "NETWRK",
    titleTemplate: "%s | NETWRK",
  },

  components: {
    Header: () => import("@/modules/Header"),
    BottomNav: () => import("@/components/BottomNav"),
  },

  data() {
    return {
      desktop: true,
    };
  },

  computed: {
    ...mapGetters(["getUserInfo"]),
  },

  created() {
    this.initStore();

    this.desktop = window?.innerWidth > 1024;

    window.addEventListener("resize", () => {
      this.desktop = window?.innerWidth > 1024;
    });
  },

  mounted() {
    if (this.getUserInfo?.type !== null && this.getUserInfo?.type !== "streamer") {
      const doc = document?.querySelector("#fb-root");
      doc?.remove();
    }

    window.addEventListener("click", (e) => {
      this.clickEvent(e);
    });
  },

  methods: {
    ...mapActions(["initStore"]),

    clickEvent(e) {
      const target = e?.target?.id?.length ? e?.target?.id : e?.target?.offsetParent?.id;

      if (
        target?.length &&
        ["app", "header", "range-value"].includes(target) === false &&
        target.indexOf("paint") === -1
      ) {
        axios
          .post(`${process.env.VUE_APP_API_URL}/log/create`, {
            event: "click",
            created_at: new Date(),
            user_type: this.getUserInfo?.type,
            user_id: this.getUserInfo?._id,
            path: this.$route?.path,
            target,
          })
          .catch((_) => {});
      }
    },
  },
};
</script>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --transition-out: all 0.1s ease-out;
  --transition-out: all 0.1s ease-in;
}

.main {
  max-width: 100vw;
  min-height: 100vh;
}

.apexcharts-tooltip,
.apexcharts-theme-light,
.apexcharts-tooltip-title,
.apexcharts-tooltip * {
  color: #101010 !important;
}
</style>
