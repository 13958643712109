import axios from "axios";
import { version } from "../../package.json";
import log from "@/lib/log";

const NETWRK_API_URL = process.env.VUE_APP_API_URL;

/**
 * @summary Initialize store on first page load
 */
export const initStore = ({ dispatch }) => {
  log("initStore");

  if (localStorage.getItem("_netwrk_v") !== version) {
    // Version control
    localStorage.clear();
    localStorage.setItem("_netwrk_v", version);
    window.location.reload();
  } else if (localStorage.getItem("userInfo") !== null && localStorage.getItem("userInfo") !== undefined) {
    // Refresh token
    dispatch("setUserInfo", JSON.parse(localStorage.getItem("userInfo")));

    if (JSON.parse(localStorage.getItem("userInfo")).type === "streamer") {
      dispatch("refreshUserInfo", JSON.parse(localStorage.getItem("userInfo"))._id);
    }
  }
};

/**
 * @summary Saves the current user in localStorage & commit to state. Dispatches event to fetch user organizations
 * @param {object} payload
 */
export const setUserInfo = async ({ commit, dispatch }, payload) => {
  log({ msg: "setUserInfo action", payload });

  const json = JSON.stringify(payload);
  localStorage.setItem("userInfo", json);

  commit("setUserInfo", payload);

  if (payload?.type === "streamer") {
    dispatch("fetchStreamerCampaigns");
    dispatch("fetchCampaignInvites");
    dispatch("fetchAvailableCampaigns");
    dispatch("fetchContentCreatorOrganizations");
  } else {
    await dispatch("fetchUserOrganizations");
  }

  dispatch("fetchNotifications");
};

/**
 * @param {string} payload - contentCreator._id
 */
export const refreshUserInfo = async ({ commit, dispatch }, payload) => {
  log({ msg: "refreshUserInfo", payload });

  const URL = `${NETWRK_API_URL}/content-creator/refresh-login/${payload}`;

  const response = await axios
    .get(URL)
    .then((res) => res.data)
    .catch((err) => {
      console.error("refreshUserInfo error", err);
    });

  if (response?.contentCreator) {
    log({ msg: "setUserInfo action", contentCreator: response.contentCreator });

    const json = JSON.stringify(response.contentCreator);
    localStorage.setItem("userInfo", json);

    commit("setUserInfo", response.contentCreator);

    if (response.contentCreator?.type === "streamer") {
      dispatch("fetchStreamerCampaigns");
      dispatch("fetchCampaignInvites");
      dispatch("fetchAvailableCampaigns");
      dispatch("fetchContentCreatorOrganizations");
    } else {
      dispatch("fetchUserOrganizations");
      dispatch("fetchOrganizationNetwrkCampaigns");
    }
  } else {
    // TODO: commit("userLogout");
  }
};

/**
 * @summary Fetch user organizations with netwrk enabled & commit to state. Dispatches event to fetch org AdCampaigns after
 */
export const fetchUserOrganizations = async ({ commit, state, dispatch }) => {
  log("fetchUserOrganizations");

  const URL = `${NETWRK_API_URL}/organizations/user/${state.userInfo._id}`;

  const orgReq = await axios
    .get(URL)
    .then((res) => res?.data)
    .catch((err) => console.error("fetchUserOrganizations error", err));

  if (orgReq?.orgs?.length) {
    commit("setOrganizations", orgReq?.orgs);

    if (
      localStorage.getItem("current_org") &&
      localStorage.getItem("current_org") !== undefined &&
      orgReq?.orgs?.findIndex((org) => org._id === localStorage.getItem("current_org")) !== -1
    ) {
      const orgIndex = orgReq?.orgs?.findIndex((org) => org._id === localStorage.getItem("current_org"));

      log({ fn: "fetchUserOrganizations", msg: "Org id is saved in localstorage", org: orgReq?.orgs[orgIndex] });

      commit("setOrganization", orgReq?.orgs[orgIndex]);

      commit("setBrands", orgReq?.orgs[orgIndex]?.brands);
    } else {
      commit("setOrganization", orgReq?.orgs[0]);

      commit("setBrands", orgReq?.orgs[0]?.brands);

      localStorage.setItem("current_org", orgReq?.orgs[0]?._id);
    }
    dispatch("fetchCampaigns");
  }
};

/**
 * @summary Fetch AdCampaigns for org & commit to state
 */
export const fetchCampaigns = async ({ commit, state }) => {
  if (!state?.organization?._id) return;

  let campaigns;

  if (state?.organization?._id === "6104316858d6180c099272b9") {
    const URL = `${NETWRK_API_URL}/ad-campaign/organization/${state.organization._id}`;

    campaigns = await axios
      .get(URL)
      .then((res) => res.data)
      .catch((err) => console.error("fetchCampaigns error", err));
  } else {
    const URL = `${NETWRK_API_URL}/organization/campaigns/${state.organization._id}`;

    campaigns = await axios
      .get(URL)
      .then((res) => res?.data?.allCampaigns)
      .catch((error) => {
        console.error("fetchOrganizationCampaigns error", error);
        return [];
      });
  }

  if (campaigns?.length) {
    // Newest to oldest
    campaigns = campaigns?.sort((a, b) => new Date(b?.date).getTime() - new Date(a?.date).getTime());
  }

  if (campaigns) {
    commit("setCampaigns", campaigns);
  }
};

export const fetchStreamerCampaigns = async ({ commit, state }) => {
  const URL = `${NETWRK_API_URL}/content-creator/campaigns/${state.userInfo.username}`;

  let campaigns = await axios
    .get(URL)
    .then((res) => res?.data?.campaigns)
    .catch((err) => console.error("fetchStreamerCampaigns error", err));

  if (campaigns?.length) {
    // Newest to oldest
    campaigns = campaigns.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  }

  commit("setCampaigns", campaigns);
};

export const fetchCampaignInvites = async ({ commit, state }) => {
  const URL = `${NETWRK_API_URL}/campaign-invites/${state.userInfo._id}`;

  const campaignInvites = await axios
    .get(URL)
    .then((res) => res.data.campaignInvites)
    .catch((err) => console.error("fetchCampaignInvites error", err));

  commit("setCampaignInvites", campaignInvites);
};

export const fetchAvailableCampaigns = async ({ commit, state }) => {
  const URL = `${NETWRK_API_URL}/content-creator/available-campaigns/${state.userInfo._id}`;

  const response = await axios
    .get(URL)
    .then((res) => res.data)
    .catch((error) => {
      console.error("fetchAvailableCampaigns error", error);

      return { campaigns: [] };
    });

  if (response?.campaigns) {
    commit("setAvailableCampaigns", response.campaigns);
  }
};

export const fetchNotifications = async ({ commit, state }) => {
  let objType = null;
  let objId = null;

  if (state?.userInfo?.type === "admin" || state?.userInfo?.type === "user") {
    objType = "organization";
    objId = state.organization?._id;
  } else if (state?.userInfo?.type === "streamer") {
    objType = "talent";
    objId = state.userInfo._id;
  }

  if (objType && objId) {
    const URL = `${NETWRK_API_URL}/get-notifications/${objType}/${objId}`;

    const response = await axios
      .get(URL)
      .then((res) => res.data)
      .catch((err) => {
        console.error("fetchNotifications errror", err.response.data);
      });

    if (response?.notifications) {
      commit("setNotifications", response.notifications);
    }
  }
};

export const fetchContentCreatorOrganizations = async ({ commit, state }) => {
  const URL = `${NETWRK_API_URL}/content-creator/organizations/${state.userInfo._id}`;

  const response = await axios
    .get(URL)
    .then((res) => res?.data?.organizations ?? [])
    .catch((error) => {
      console.error(error);
      return [];
    });

  if (response) {
    commit("setContentCreatorOrganizations", response);
  }
};

export const switchOrganization = async ({ state }, payload) => {
  log({ fn: "switchOrganization", payload });

  const organization = state?.organizations?.filter((org) => org?._id === payload);

  log({ fn: "switchOrganization", organization });

  if (organization?.length) {
    localStorage.setItem("current_org", organization[0]?._id);

    // Reloading to make sure we don't miss any calls
    window.location.href = "/dashboard";
    window.location.reload();
  }
};
