import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import store from "@/store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "",
    redirect: () => {
      if (localStorage.getItem("userInfo") === null || store.state.userInfo === null) {
        return "/login";
      }

      if (store?.state?.userInfo?.type === "streamer" && !store?.state?.userInfo?.info?.broadcastingLanguage) {
        return "/flow";
      }

      return "/dashboard";
    },
  },
  {
    path: "/manager",
    name: "ManagerLogin",
    component: () => import("@/views/Login"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/login",
    name: "StreamerLogin",
    component: () => import("@/views/streamer/StreamerLogin"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/signup",
    name: "StreamerSignup",
    component: () => import("@/views/streamer/StreamerSignup"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/flow",
    name: "StreamerSignupFlow",
    component: () => import("@/views/streamer/StreamerSignupFlow"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/Dashboard"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "StreamerProfile",
    path: "/profile/:handle",
    component: () => import("@/views/streamer/StreamerProfile"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/campaigns/:id",
    name: "Campaign",
    component: () => import("@/views/campaigns/Campaign"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("@/views/admin/Admin"),
    meta: {
      title: "User Statistics",
      requiresAuth: true,
      requiresAdminOrganization: true,
    },
    children: [
      {
        path: "",
        name: "AdminStatistics",
        meta: {
          title: "User Statistics",
          requiresAuth: true,
          requiresAdminOrganization: true,
        },
        component: () => import("@/views/admin/AdminStatistics"),
      },
      {
        path: "campaigns",
        name: "AdminCampaigns",
        meta: {
          title: "Campaign Statistics",
          requiresAuth: true,
          requiresAdminOrganization: true,
        },
        component: () => import("@/views/admin/AdminCampaigns"),
      },
      {
        path: "demographics",
        name: "AdminDemographics",
        meta: {
          title: "User Demographics",
          requiresAuth: true,
          requiresAdminOrganization: true,
        },
        component: () => import("@/views/admin/AdminDemographics"),
      },
      {
        path: "creator-pages",
        name: "AdminCreatorPages",
        meta: {
          title: "Creator Page Statistics",
          requiresAuth: true,
          requiresAdminOrganization: true,
        },
        component: () => import("@/views/admin/AdminCreatorPages"),
      },
      {
        path: "earnings",
        name: "AdminEarnings",
        meta: {
          title: "Earning Statistics",
          requiresAuth: true,
          requiresAdminOrganization: true,
        },
        component: () => import("@/views/admin/AdminEarnings"),
      },
      {
        path: "discover",
        name: "AdminDiscover",
        meta: {
          title: "Discover",
          requiresAuth: true,
          requiresAdminOrganization: true,
        },
        component: () => import("@/views/admin/AdminDiscover"),
      },
    ],
  },
  {
    path: "/create",
    name: "Create",
    component: () => import("@/views/Create"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: "CreateCampaignSettings",
        path: "/create/campaign/settings",
        meta: {
          title: "Campaign settings",
          requiresAuth: true,
        },
        component: () => import("@/views/campaigns/create/CreateCampaignSettings"),
      },
      {
        name: "CreateCampaignStreamers",
        path: "/create/campaign/streamers",
        meta: {
          title: "Streamers",
          requiresAuth: true,
        },
        component: () => import("@/views/campaigns/create/CreateCampaignStreamers"),
      },
      {
        name: "CreateCampaignContent",
        path: "/create/campaign/content",
        meta: {
          title: "Content",
          requiresAuth: true,
        },
        component: () => import("@/views/campaigns/create/CreateCampaignContent"),
      },
      {
        name: "CreateCampaignCompleted",
        path: "/create/campaign/completed",
        component: () => import("@/views/campaigns/create/CreateCampaignCompleted"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/create/campaign/overlay",
        name: "StreamOverlay",
        component: () => import("@/views/Overlay/StreamOverlay"),
        meta: {
          title: "Overlay",
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/wallet",
    name: "Wallet",
    component: () => import("@/views/Wallet"),
    meta: {
      title: "Wallet",
      requiresAuth: true,
    },
  },
  {
    path: "/streamers",
    name: "OrganizationStreamers",
    component: () => import("@/views/organization/OrganizationStreamers"),
    meta: {
      title: "Streamers",
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "StreamerSettings",
    component: () => import("@/views/streamer/StreamerSettings"),
    meta: {
      title: "Settings",
      requiresAuth: true,
    },
  },
  {
    path: "/page-builder",
    name: "StreamerCreatorPageBuilder",
    component: () => import("@/views/streamer/StreamerCreatorPageBuilder"),
    meta: {
      title: "Creator page builder",
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, _from) => {
  const userId = store?.state?.userInfo?._id;
  const userType = store?.state?.userInfo?.type;

  store.dispatch("fetchNotifications");

  axios
    .post(`${process.env.VUE_APP_API_URL}/log/create`, {
      event: "user_nav",
      path: to?.path,
      user_id: userId,
      created_at: new Date(),
      user_type: userType,
    })
    .catch((error) => console.error("oops", error));
});

// TODO: Actually make this secure
router.beforeEach((to, _from, next) => {
  window.scrollTo(0, 0);

  if (to?.matched?.some((record) => record?.meta?.requiresAuth)) {
    if (localStorage.getItem("userInfo") === null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });

      window.scrollTo(0, 0);

      return;
    }

    if (store?.state?.userInfo === null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });

      window.scrollTo(0, 0);

      return;
    }

    if (
      to?.matched?.some(
        (record) =>
          record?.meta?.requiresAdminOrganization &&
          store?.state?.organization?._id !== "6104316858d6180c099272b9" &&
          localStorage.getItem("current_org") !== "6104316858d6180c099272b9"
      )
    ) {
      next({
        path: "/",
        params: { nextUrl: to.fullPath },
      });

      window.scrollTo(0, 0);
      return;
    }
  }

  next();

  window.scrollTo(0, 0);
});

export default router;
