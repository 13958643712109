/**
 * @summary Get current organization
 * @param {object} state - Vuex state
 * @returns {object} state.organization
 */
export const getOrganization = (state) => state?.organization ?? null;

/**
 * @summary Get all user organization
 * @param {object} state - Vuex state
 * @returns {object} state.organizations
 */
export const getOrganizations = (state) => state?.organizations || [];

/**
 * @summary Get all organization campaigns
 * @param {object} state - Vuex state
 * @returns {object[]} state.campaigns
 */
export const getCampaigns = (state) => state?.campaigns || [];

/**
 * @param {object} state - Vuex state
 * @typedef {{
 *  _id: string,
 *  email: string,
 *  fullname: string,
 *  type: string,
 *  password: string,
 *  gaid: string,
 *  last_seen: string | Date,
 *  settings: object,
 * }} UserInfo
 * @returns {UserInfo} state.userInfo
 */
export const getUserInfo = (state) => state.userInfo;

/**
 * @summary Returns the current campaign
 * @param {object} state - Vuex state
 * @returns {object} state.campaign
 */
export const getCampaign = (state) => state.campaign;

/**
 * @summary Returns array of all organization brands
 * @param {object} state - Vuex state
 * @returns {object[]} state.brands
 */
export const getBrands = (state) => state.brands;

/**
 * @summary Returns array of all organization brands
 * @param {object} state - Vuex state
 * @returns {object[]} state.brands
 */
export const getCampaignInvites = (state) => state.campaignInvites;

/**
 * @summary Returns array of all available brands
 * @param {object} state - Vuex state
 * @returns {object[]} state.availableCampaigns
 */
export const getAvailableCampaigns = (state) => state.availableCampaigns;

/**
 * @summary Returns array of all notifications
 * @param {object} state - Vuex state
 * @returns {object[]} state.notifications
 */
export const getNotifications = (state) => state.notifications;

export const getContentCreatorOrganizations = (state) => state.contentCreatorOrganizations;
