import Vue from "vue";
import Vuex from "vuex";
import * as getters from "./getters";
import * as actions from "./actions";
import * as mutations from "./mutations";

Vue.use(Vuex);

function initialStateFromLocalStorage() {
  if (localStorage.getItem("userInfo") != null && localStorage.getItem("userInfo") !== undefined) {
    return JSON.parse(localStorage.getItem("userInfo"));
  }
  return null;
}

export default new Vuex.Store({
  state: {
    userInfo: initialStateFromLocalStorage(),
    organization: null,
    organizations: [],
    campaigns: null,
    brands: null,
    campaign: {
      title: "",
      orgId: "",
      active: true,
      public: false,
      date: new Date(),
      thumbnail: "",
      displayFreq: 60, // Times to show image + send chat message per hour.
      targets: [],
      handles: [],
      brand: {
        id: "",
        title: "",
      },
      info: {
        description: "",
        priceRate: null,
        broadcastingLanguages: [],
      },
      tools: {
        redirector: [], // Is this even needed?
        chatbot: {
          text: "",
          link: "", // Original link
          utmCodes: [],
        },
        overlay: {
          screenWidth: 1920,
          screenHeight: 1080,
          align: {
            h: "flex-start",
            v: "flex-start",
          },
          carousel: {
            duration: 5000,
            width: 400,
            height: 300,
            x: 0,
            y: 0,
            activeSlide: 0,
            imageArr: [],
          },
        },
      },
    },
    campaignInvites: null,
    availableCampaigns: null,
    notifications: null,
    contentCreatorOrganizations: null,
  },
  mutations,
  getters,
  actions,
});
