import log from "@/lib/log";

/**
 * @summary Set organization campaigns
 * @param {object} state - Vuex state
 * @param {object[]} payload - Array of campaigns
 */
export const setCampaigns = (state, payload) => {
  log({ msg: "setCampaigns", payload });

  state.campaigns = payload;
};

/**
 * @summary Set userInfo
 * @param {object} state - Vuex state
 * @param {object} payload - userInfo
 */
export const setUserInfo = (state, payload) => {
  log({ msg: "setUserInfo", payload });

  state.userInfo = payload;
};

/**
 * @summary Set all user organizations
 * @param {object} state - Vuex state
 * @param {object[]} payload - Array of organizations with netwrk enabled
 */
export const setOrganizations = (state, payload) => {
  log({ msg: "setOrganizations", payload });

  state.organizations = payload;
};

/**
 * @summary Set current organization
 * @param {object} state - Vuex state
 * @param {object} payload - Organization
 */
export const setOrganization = (state, payload) => {
  log({ msg: "setOrganization", payload });

  state.organization = payload;
};

/**
 * @summary Update current campaign
 * @param {object} state - Vuex state
 * @param {object} payload - Campaign
 */
export const setCampaign = (state, payload) => {
  log({ msg: "setCampaign", payload });

  state.campaign = payload;
};

/**
 * @summary Update state.campaign.tools.overlay
 * @todo Remove this and user setCampaign instead
 * @param {object} state - Vuex state
 * @param {object} payload - Overlay
 */
export const setNewOverlay = (state, payload) => {
  log({ msg: "setNewOverlay", payload });

  state.campaign.tools.overlay = payload;
};

/**
 * @summary Set brands
 * @param {object} state - Vuex state
 * @param {object[]} payload - Array of brands
 */
export const setBrands = (state, payload) => {
  state.brands = payload;
};

/**
 * @summary Resets the campaign state to a clean state
 * @param {object} state - Vuex state
 */
export const resetNewCampaign = (state) => {
  log("mutation resetNewCampaign");

  state.campaign = {
    title: "",
    orgId: state.organization._id,
    active: true,
    public: false,
    date: new Date(),
    thumbnail: "",
    displayFreq: 60, // Times to show image + send chat message per hour.
    targets: [],
    handles: [],
    brand: {
      id: "",
      title: "",
    },
    info: {
      description: "",
      priceRate: null,
      broadcastingLanguages: [],
      payableDate: undefined,
    },
    tools: {
      redirector: [], // Is this even needed?
      chatbot: {
        text: "",
        link: "", // Original link
      },
      overlay: {
        screenWidth: 1920,
        screenHeight: 1080,
        align: {
          h: "flex-start",
          v: "flex-start",
        },
        carousel: {
          duration: 5000,
          width: 400,
          height: 300,
          x: 0,
          y: 0,
          activeSlide: 0,
          imageArr: [],
        },
      },
    },
  };
};

/**
 * @summary Set existing campaign as editable
 * @param {object} state - Vuex state
 * @param {string} payload - AdCampaignId
 */
export const setExistingCampaign = (state, payload) => {
  state.campaign = state.campaigns.find((campaign) => campaign._id === payload);
};

/**
 * @summary Set existing campaign as editable
 * @param {object} state - Vuex state
 * @param {string} payload - AdCampaignId
 */
export const setCampaignInvites = (state, payload) => {
  state.campaignInvites = payload;
};

export const userLogout = () => {
  localStorage.clear();

  this.$router.go();
  window.location.reload();
};

export const setAvailableCampaigns = (state, payload) => {
  state.availableCampaigns = payload;
};

export const setNotifications = (state, payload) => {
  state.notifications = payload;
};

export const setContentCreatorOrganizations = (state, payload) => {
  state.contentCreatorOrganizations = payload;
};
